import { gtag } from "ga-gtag";

import settings from "../settings";

const trackBookingEvent = (url) => {
  gtag("event", "conversion", {
    send_to: `${settings.GTagCode}/uBbFCKTeqYEYEJqqvc4B`,
    bookAppointmentTrigger: 'website',
    value: 28.25,
    currency: 'CAD'
  });
};

export default trackBookingEvent;

import { useMemo, useContext } from "react";
import Context from '../context/context'
import CTA from "../CTA/cta.module";
import Picture from "../picture/picture.module";
import settings from "../../util/settings";
import { DEFAULT_LOCATION } from "../../util/locations";
import styles from "./gift-certificates.module.scss";
  
const ASSET_PATH = settings.ASSET_PATH + "images/components/gift-certificates/";

const GiftCertificates = ({ analytics }) => {
  const { location } = useContext(Context);
  const locationToUse = useMemo(() => location ? location : DEFAULT_LOCATION, [location]);

  return (
    <section
      className={`section-content ${styles["gift-certificates-module"]}`}
    >
      <Picture
        src={`${ASSET_PATH}gift-cards-section-bg.jpg`}
        alt="Hand picking up golf ball"
        title="Hand picking up golf ball"
        className={styles["gift-certificates-module-background"]}
      />
      <h3 className={styles["gift-certificates-module-heading"]}>
        Why not give the gift of golf?
      </h3>
      <div className={styles["gift-certificates-module-copy"]}>
        <p className="text-body-medium">
          We offer digital gift certificates in a variety of denominations.
          <br />
          Vouchers can be used to book any bay for any duration of time, until
          funds are diminished. Gift certificates never expire.
        </p>
      </div>
      <CTA
        href={locationToUse.endpoints.GIFT_CERTIFICATES}
        className="button mt-4 mb-5"
        text="Buy a gift certificate"
        analytics={analytics}
        src="/pricing"
        dest={locationToUse.endpoints.GIFT_CERTIFICATES}
      />
    </section>
  );
};

export default GiftCertificates;

import { useMemo, useContext } from "react";
import { DEFAULT_LOCATION } from "../../util/locations";
import Context from "../context/context";
import CTA from "../CTA/cta.module";
import { Pricing as data } from "../../util/data";
import styles from "./memberships.module.scss";
import settings from "../../util/settings";

const ASSET_PATH = settings.ASSET_PATH + "images/components/memberships/";

const Memberships = ({ analytics }) => {
  const { location } = useContext(Context);
  const locationToUse = useMemo(
    () => (location ? location : DEFAULT_LOCATION),
    [location]
  );

  return (
    <div className={styles["memberships-module"]}>
      <ul className={`row ${styles["memberships-list"]}`}>
        {data.memberships.map((pkg, index) => (
          <li
            className={`col-lg-4 col-md-6 ${styles["memberships-list-item"]}`}
            key={"membership" + index}
          >
            <img src={ASSET_PATH + pkg.image} />
            {/* <div className={styles['memberships-list-item-content']}>
                    <h3>{pkg.name}</h3>
                    <p className={styles['membership-bonus']}>{pkg.bonus}</p>
                    <p className={`text-body-large ${styles['membership-subtitle']}`}>{pkg.subtitle}</p>
                    <p className={`text-body-medium ${styles['membership-description']}`}>{pkg.description}</p>
                </div> */}
          </li>
        ))}
      </ul>
      <CTA
        href={locationToUse.endpoints.MEMBERSHIPS}
        className={`button mb-5 ${styles["cta-button"]}`}
        target="_blank"
        text="Buy a Membership"
        analytics={analytics}
        src="/pricing"
        dest={locationToUse.endpoints.MEMBERSHIPS}
      />
    </div>
  );
};

export default Memberships;

import { useState, useContext, useMemo, useCallback, useRef, useEffect } from "react";
import Head from "next/head";
// import Link from 'next/link'
// import Image from 'next/image'
import Page from "../layouts/main";
import Context from "../components/context/context";
import Footer from "../components/Footer/footer.module";
import Picture from "../components/picture/picture.module";
import Video from "../components/video/video.module";
import CTA from "../components/CTA/cta.module";
import Pricing from "../components/pricing/pricing.module";
import GiftCertificates from "../components/gift-certificates/gift-certificates.module";
import Memberships from "../components/memberships/memberships.module";
import styles from "../styles/modules/index.module.scss";
import { landing as data } from "../util/data";
import settings from "../util/settings";
import trackBookingEvent from "../util/conversion-events/booking-event";
import { DEFAULT_LOCATION } from "../util/locations";

const Home = () => {
  const { location } = useContext(Context);
  const locationToUse = useMemo(
    () => (location ? location : DEFAULT_LOCATION),
    [location]
  );

  const [analytics, setAnalytics] = useState({});
  const [orientation, setOrientation] = useState("");
  const [playVideo, setPlayVideo] = useState();

  const page = useRef();

  const isLandscape = useMemo(() => orientation === "landscape", [orientation]);

  const updateOrientation = useCallback(() => {
    const newOrientation =
      settings.isServer() || window.innerWidth > window.innerHeight
        ? "landscape"
        : "portrait";
    if (orientation !== newOrientation) {
      setOrientation(newOrientation);
    }
  }, [orientation]);

  const updateVideoPlayState = useCallback(() => {
    const playVideoNew =
      !settings.isServer() && window.pageYOffset < window.innerHeight;
    if (playVideo !== playVideoNew) {
      setPlayVideo(playVideoNew);
    }
  }, [playVideo]);

  const getVideoHeight = useCallback(() => {
    if (!settings.isServer()) {
      if (orientation === "landscape") {
        if (window.innerHeight <= 500) {
          return "calc(200vh - 140px)";
        } else if (window.innerHeight <= 700) {
          return "calc(110vh - 140px)";
        }
      } else {
        return "calc(100vh - 140px)";
      }
    }
    return "calc(100vh - 140px)";
  }, [orientation]);

  useEffect(() => {
    if (page.current) {
      setAnalytics(page.current.analytics);
    }
    window.addEventListener("resize", updateOrientation);
    window.addEventListener("scroll", updateVideoPlayState);
    updateOrientation();
    updateVideoPlayState();

    return () => {
      window.removeEventListener("resize", updateOrientation);
      window.removeEventListener("scroll", updateVideoPlayState);
    };
  }, []);

  useEffect(() => {
    window.removeEventListener("resize", updateOrientation);
    window.addEventListener("resize", updateOrientation);
  }, [updateOrientation]);

  useEffect(() => {
    window.removeEventListener("resize", updateVideoPlayState);
    window.addEventListener("resize", updateVideoPlayState);
  }, [updateVideoPlayState]);

  return (
    <Page id={styles["page-landing"]} ref={page}>
      <Head>
        <title>Kudos Golf - Indoor Virtual Driving Range</title>
      </Head>
      <section className={`${styles["section-brand-introduction"]}`}>
        <Video
          url={`${settings.ASSET_PATH}videos/virtual-golf-simulation.mp4`}
          key={orientation}
          style={{
            minWidth: "100vw",
            maxHeight: getVideoHeight(),
            overflow: "hidden",
          }}
          height="100%"
          width={isLandscape ? "100%" : "auto"}
          playing={playVideo}
          loop={true}
          muted={true}
          playsinline={true}
        />

        <div
          className={`section-content ${styles["section-brand-introduction-content"]}`}
        >
          <h2 className={styles["brand-title"]}>
            24/7 Indoor Driving Range and Virtual Golf
          </h2>
          <p className={`text-body-large ${styles["brand-description"]}`}>
            The most realistic VR golf simulation experience, offering real time
            feedback data to improve your game all year round.
          </p>
          <CTA
            href={locationToUse.endpoints.SCHEDULER}
            className={`button ${styles["booking-cta"]}`}
            onClick={trackBookingEvent}
            text="Book a session"
            analytics={analytics}
            src="/"
            dest="booking-system"
          />
        </div>
      </section>

      <section className={`row section-content ${styles["section-features"]}`}>
        {data.features.map((feature, index) => (
          <div
            className={`col-md-4 ${styles["feature-container"]}`}
            key={index}
          >
            {/* <Image src={ settings.ASSET_PATH + feature.icon } height={60} width={60} alt={feature.title} title={feature.title}/> */}
            <Picture
              src={settings.ASSET_PATH + feature.icon}
              style={{ height: 60, width: 60 }}
              alt={feature.title}
              title={feature.title}
            />
            <h4 className={styles["feature-title"]}>{feature.title}</h4>
            <p className={`text-body-small ${styles["feature-description"]}`}>
              {feature.description}
            </p>
          </div>
        ))}
      </section>

      <Pricing />

      <section
        className={`row section-content ${styles["section-memberships"]}`}
      >
        <p className={`text-body-large ${styles["section-memberships-copy"]}`}>
          With our Memberships, our frequent golfers can get more value by
          pre-paying for multiple sessions in advance.
        </p>
        <Memberships analytics={analytics} />
      </section>

      <section className={`row section-content ${styles["section-service"]}`}>
        <span className="col-md-5 p-0">
          {/* <Image src={ settings.ASSET_PATH + 'images/home/golf-simulation-bay.jpg?webp' } height={900} width={1000} alt={'Golf Clubs'} title={'Golf Clubs'}/> */}
          <Picture
            src={
              settings.ASSET_PATH + "images/home/golf-simulation-bay.jpg?webp"
            }
            style={{ width: "100%" }}
            alt={"Golf Clubs"}
            title={"Golf Clubs"}
          />
        </span>
        <span
          className={`offset-md-1 col-md-5 ${styles["section-service-content"]}`}
        >
          <h3 className={styles["service-section-title"]}>How It Works</h3>
          <p
            className={`text-body-medium ${styles["service-section-description"]}`}
          >
            Kudos Golf uses state-of-the art launch monitors to add an
            unprecedented leap in real-time accessibility to actual ball spin
            and club path movement data. Our software includes a driving range,
            along with over 90 different 18 hole courses. Create a player
            profile prior to your session to save your data and track your
            statistics.
          </p>
          <CTA
            href="/how-it-works"
            className={`button-arrow ${styles["how-it-works-cta"]}`}
            text="Learn more"
            analytics={analytics}
            src="/"
            dest="/how-it-works"
          />
        </span>
      </section>

      <GiftCertificates analytics={analytics} />

      <Footer />
    </Page>
  );
};

export default Home
import { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import CTA from "../CTA/cta.module";
import Context from "../context/context";
import styles from "./pricing.module.scss";
import { DEFAULT_LOCATION } from "../../util/locations";

import trackBookingEvent from "../../util/conversion-events/booking-event";

const Pricing = ({ analytics, theme, size }) => {
  const { location } = useContext(Context);
  const locationToUse = useMemo(
    () => (location ? location : DEFAULT_LOCATION),
    [location]
  );
    
  const dark = theme === "dark";
  const mini = size === "mini";

  return (
    <section
      className={`${mini ? "" : "section-content"} ${styles["pricing-module"]}`}
    >
      <div
        className={`${styles["pricing-background"]} ${
          dark ? styles["dark"] : ""
        } ${mini ? styles["mini"] : ""} `}
      >
        {/* <h3 className={`${styles['pricing-title']}`}>Grand Opening Special Rate</h3> */}
        <h1 className={styles["pricing-rate"]}>
          ${locationToUse.price.openBay}/hour<sup>+ tax</sup>
        </h1>
        {/* <p className={'text-body-medium ' + styles['original-rate']}>
            Enjoy 15% OFF your booking for a limited time only! <span className={ styles['line'] }>Offer ends March 6th, 2021.</span></p> */}
        <CTA
          href={locationToUse.endpoints.SCHEDULER}
          onClick={trackBookingEvent}
          className={`button ${styles["pricing-booking-cta"]}`}
          text="Book now"
          analytics={analytics}
          src="/"
          dest="booking-system"
        />

        <p className={styles["pricing-conditions"]}>
          Minimum booking time of 30 minutes.
          <br />
          Unlimited balls. Please bring your own clubs.
        </p>
      </div>
    </section>
  );
};

export default Pricing

Pricing.propTypes = {
  analytics: PropTypes.object,
  theme: PropTypes.string,
  size: PropTypes.string,
};

Pricing.defaultProps = {
  theme: "",
  size: "",
};
